<template>
  <div>
    <div v-if="isActive === 1" class="theme">
      <menuHeader
        v-if="card_style == 1"
        class="content"
        :header-data="set_data"
        :socialmedia="set_social"
      />
      <menuHeader2
        v-else-if="card_style == 3"
        :header-data="set_data"
        :socialmedia="set_social"
      />
      <menuHeader3
        v-else-if="card_style == 2"
        class="content"
        :header-data="set_data"
        :socialmedia="set_social"
      />
      <menuHeader
        v-else
        class="content"
        :header-data="set_data"
        :socialmedia="set_social"
      />
      {{ getCategory }}
      <tab-dynamic
        class="content"
        :a-p-i-url="APIUrl"
        :template="template"
        :template_id="card_style"
        :categories="parent_cat"
        :sub_categories="sub_cat"
        :settings="set_data"
      />
      <menufooter
        :footer-data="set_data"
        :socialmedia="set_social"
        :num="card_style"
      />
    </div>
    <noActive v-else />
  </div>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from "bootstrap-vue";
import axios from "axios";
import topic from "./components/topic.vue";
import TabDynamic from "./components/TabDynamic.vue";
import menuHeader from "./components/menuHeader.vue";
import menuHeader2 from "./components/menuHeader2.vue";
import menuHeader3 from "./components/menuHeader3.vue";
import noActive from "./error/NotActive.vue";
import menufooter from "./components/footer.vue";

export default {
  components: {
    BRow,
    BCol,
    topic,
    BTabs,
    BTab,
    TabDynamic,
    menuHeader,
    menuHeader2,
    menuHeader3,
    noActive,
    menufooter,
  },
  data() {
    return {
      set_data: [],
      set_social: [],
      categories: [],
      template: false,
      isActive: 1,
      APIUrl: "https://menu.smarterp.top",
      supported_templetes: [1, 2, 3], // important
      card_style: null,
      parent_cat: [],
      sub_cat: [],

      products: [],
      cardData: [],
    };
  },
  computed: {
    getCategory() {
      // this.parent_cat = this.categories.filter(cat => cat.parent_id == 0)

      // get catgory
      for (let i = 0; i < this.categories.length; i++) {
        const parent = this.categories[i];
        if (!parent.parent_id) {
          this.parent_cat.push(parent);
        }

        // get subcategory
        for (let e = 0; e < this.categories.length; e++) {
          const sub = this.categories[e];
          if (sub.parent_id == parent.c_id) {
            this.sub_cat.push(sub);

            console.log("sub_cat", this.sub_cat);
          } else {
          }
        }
      }
    },
  },

  created() {
    console.log("pre_base_url", window);
    if (typeof window.pre_base_url === "undefined") var pre_base_url = false;
    else var { pre_base_url } = window;
    if (typeof window.pre_settings === "undefined") var pre_settings = false;
    else var { pre_settings } = window;
    if (typeof window.pre_categories === "undefined")
      var pre_categories = false;
    else var { pre_categories } = window;

    if (pre_base_url) this.APIUrl = pre_base_url;
    const { resturant } = this.$route.params;
    this.template = this.$route.params.template || false;

    if (
      this.template &&
      !this.supported_templetes.includes(parseInt(this.template))
    ) {
      this.template = 1;
    }
    if (!pre_settings) {
      try {
        axios
          .post(`${this.APIUrl}/api/get_settings/${resturant}`)
          .then((res) => {
            this.set_data = res.data.data;
            this.set_social = res.data.social_media;
            this.isActive = this.set_data.active;
            this.updatecolor();
            this.setThemeTemplete();
          });
      } catch (e) {
        console.error(e);
      }
    } else {
      this.set_data = pre_settings;
      this.setThemeTemplete();
      this.set_social = pre_social_media || [];
      this.updatecolor();
    }

    if (!pre_categories) {
      try {
        axios
          .post(`${this.APIUrl}/api/get_categories/${resturant}`)
          .then((res) => {
            this.categories = res.data.data;
            console.log(this.categories, "categories");
          });
      } catch (e) {
        console.error(e);
      }
    } else {
      this.categories = pre_categories;
    }

    try {
      const { resturant } = this.$route.params;
      axios.post(`${this.APIUrl}/api/get_products/${resturant}`).then((res) => {
        this.products = res.data.data;
        this.cardData = res.data;
        console.log(this.products, "products from pro");
      });
    } catch (e) {
      console.error(e);
    }
  },

  methods: {
    setThemeTemplete() {
      if (this.template) {
        this.card_style = this.template;
      } else {
        this.card_style = this.set_data.menu_id;
      }
      if (!this.supported_templetes.includes(parseInt(this.card_style))) {
        this.card_style = 1;
      }
    },
    updatecolor() {
      try {
        if (this.set_data?.theme_color) {
          document.documentElement.style.setProperty(
            "--primary",
            this.set_data?.theme_color
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
// $primary: var(--primary);
</style>
